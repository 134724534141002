import { axios, getToken, setToken, clearToken } from "@/utils/axios";
import useConnectionApi from "./useConnection";

const path_stripe_api = process.env.VUE_APP_STRIPE_API_URL;

const fields = ["id", "name", "email", "vat", "razon_social", "id_stripe"];

export default function useUserApi() {

  const signIn = async (username, password) => {
    try {
      console.log("signIn");
      // console.log(username, password);
      // Open connection with public user
      const { openConnection } = useConnectionApi();
      const { data: connection, status: connectionStatus } = await openConnection({type: "public"});

      if (connectionStatus === 200) {
        // Set token with public user
          setToken(connection.payload);
          // Get user
        const { data, status } = await axios.post("/api/v1/res.partner", {
          method: "search_read",
          where: [[["id_portal", "=", username], ["password_portal", "=", password]]],
          params: {
            limit: 1,
            fields: fields,
          },
        });
        if (status === 200) {
            let user = data.payload[0];
            // console.log("************");
            // console.log(user);
            // console.log("************");
            let stripe_customer_id = null;
            if (user.id_stripe === null || user.id_stripe === false) {
              const {data: customer} = await createCustomerStripeId(user);
              // console.log("------");
              // console.log(customer);
              // console.log("------");
              stripe_customer_id = customer.id
              user.id_stripe = stripe_customer_id;
              console.log("customer_id:", stripe_customer_id);
              await update_customer_id(user.id, stripe_customer_id);
            }
            // console.log(user);
            // Clear token with public user
            clearToken();
            // console.log("++++++++++++");
            // console.log(user);
            // console.log("++++++++++++");
            // Open connection with user authenticated
            const { data: connectionUser, status: connectionUserStatus } = await openConnection({type: "user", ...user});
            if (connectionUserStatus === 200) {
              // console.log("connectionUser:", connectionUser.payload);
              // Set token with user authenticated
              const session = connectionUser.payload;
              setToken(session);
              return user;
            }
        }
      }

      return null;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const createCustomerStripeId = async (fields) => {
    console.log("Asignando el customer id ...")
    const path = path_stripe_api + "customers";
    console.log(path);
    const customer = {
      id_odoo: fields.id,
      name: fields.name,
      email: fields.email,
      phone: fields.phone || null,
      description: fields.razon_social || null,
      city: fields.city || null,
      state: fields.state || null,
      postal_code: fields.postal_code || null,
      country: fields.country || "mx",
      line1: fields.line1 || null,
      line2: fields.line2 || null,
    }
    
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(customer)
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating Stripe customer:', error);
      throw error;
    }
  }

  const publicUser = async () => {
    try {
      // Open connection with public user
      const { openConnection } = useConnectionApi();
      const { data: connection, status: connectionStatus } = await openConnection({type: "public"});
      if (connectionStatus === 200) {
        setToken(connection.payload);
        return connection.payload;
      }
      throw new Error("Failed to authenticate public user");
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const getPassword = async (user_id) => {
    try {
      const { data, status } = await axios.post("/api/v1/res.partner", {
          method: "search_read",
          where: [[["id", "=", user_id]]],
          params: {
              fields: ["password_portal"],
          },
      });
      return data.payload[0].password_portal;
  } catch (error) {
      console.error("Error listing services by user:", error);
      throw error;
  }
  }

  const changePassword = async (user_id, current_password, new_password, confirm_password) => {
    try {
      const password_database = await getPassword(user_id);
      if (password_database === current_password) {
        if (new_password === confirm_password) {
          const { data, status } = await axios.post("/api/v1/res.partner", {
            method: "write",
            where: [[parseInt(user_id)], { "password_portal": new_password }]
          });
          if (status === 200) {
            console.log("Password changed successfully");
            // console.log(data);
            return true;
          }
          throw new Error("Failed to change password");
        }
        throw new Error("New password and confirm password do not match");
      }
      throw new Error("Current password does not match");
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const update_customer_id = async (user_id, customer_id) => {
    try {
      console.log("update_customer_id");
      const { data, status } = await axios.post("/api/v1/res.partner", {
        method: "write",
        where: [[parseInt(user_id)], { "id_stripe": customer_id }]
      });
      if (status === 200) {
        console.log("Customer id updated successfully");
        // console.log(data);
        return true;
      }
      throw new Error("Failed to update customer id");
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const sendResetPasswordEmail = async (email) => {
    console.log(email);
    try {
      // Validate email format using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error("Invalid email format");
      }

      const { data, status } = await axios.post("/api/v1/mikrotik.servicio", {
        method: "verificar_correo_contacto",
        where: [false, email]
      });

      if (status === 200) {
        return data.payload;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const newUserPortal = async (service_number) => {
    try {
      console.log("newUserPortal");
      const { data, status } = await axios.post("/api/v1/mikrotik.servicio", {
        method: "crear_usuario_portal_api",
        where: [false, service_number]
      });
      // console.log(data);
      if (status === 200) {
        return data.payload;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  return {
    signIn,
    publicUser,
    changePassword,
    sendResetPasswordEmail,
    newUserPortal
  };
}

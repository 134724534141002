import { axios, getToken, setToken, clearToken } from "@/utils/axios";

export default function useConnectionApi() {
  const username = window.atob(process.env.VUE_APP_ENCRYPT_PASSWORD);
  const password = window.atob(process.env.VUE_APP_ENCRYPT_SALT);

  const openConnection = async (metadata) => {
    try {
        const fields = {
            username,
            password,
            metadata,
        };

      const data = await axios.post("/api/auth", fields);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    openConnection,
  };
}


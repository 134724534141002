<script>
import { ref, onMounted, computed, defineAsyncComponent, watch } from "vue";
import { useRoute } from "vue-router";
import jwt_decode from "jwt-decode";
import AppLoading from "@/components/AppLoading";
import AppTitle from "@/components/AppTitle";
import AppHelpText from "@/components/AppHelpText";
import AppUpdateAvailable from "@/components/AppUpdateAvailable";

import { axios, getToken } from "@/utils/axios";
import { store } from "@/store";

// Api
import useApi from "@/composables/useApi";
export default {
  components: {
    Blank: defineAsyncComponent(() => import("@/layouts/Blank.vue")),
    Default: defineAsyncComponent(() => import("@/layouts/Default.vue")),
    AppLoading,
    AppTitle,
    AppHelpText,
    AppUpdateAvailable,
  },
  setup() {
    const { service, invoice } = useApi();
    const route = useRoute();
    const layout = computed(() => {
      return route.meta.layout || "Default";
    });

    const getServices = async (user_id) => {
      try {
        // console.log("getServices");
        const data = await service.listServicesByUser(user_id);
        if (data && data.payload) {
          store.services = data.payload;
          // console.log("store.services", store.services);
          return data.payload;
        }
        return [];
      } catch (error) {
        console.error("Error getting services:", error);
        return [];
      }
    };

    const getInvoices = async (partner_id) => {
      try {
        const data = await invoice.listInvoices(partner_id);
        if (data && data.payload) {
          store.invoices = data.payload;
          return data.payload;
        }
        return [];
      } catch (error) {
        console.error("Error getting invoices:", error);
        return [];
      }
    };

    const initializeUserAndServices = async () => {
      const { access_token } = getToken();

      if (access_token) {
        try {
          const decoded = jwt_decode(access_token);
          const sub = decoded.sub;
          let stringJSONValido = sub.replace(/'/g, '"');
          const user = JSON.parse(stringJSONValido);
          store.user = user.metadata;
          
          // Verificar si ya existen servicios en el store
          if (!store.services || store.services.length === 0) {
            await getServices(user.metadata.id);
          }
          if (!store.invoices || store.invoices.length === 0) {
            await getInvoices(user.metadata.id);
          }
        } catch (error) {
          console.error("Error initializing user and services:", error);
        }
      }
    };

    onMounted(async () => {
      console.log("onMounted App");
      await initializeUserAndServices();
    });

    // Observar cambios en store.user para asegurar que los servicios se carguen
    watch(() => store.user, async (newUser) => {
      if (newUser && newUser.id && (!store.services || store.services.length === 0)) {
        await getServices(newUser.id);
        await getInvoices(newUser.id);
      }
    }, { deep: true });

    return { layout };
  },
};
</script>

<template lang="pug">
component(:is="layout")
  router-view(v-slot="{ Component, route }")
    transition(name="route" mode="out-in")
      .h-full
        //- AppUpdateAvailable
        AppTitle
        component(:is="Component")
        AppHelpText GigNet, S.A. de C.V. a través de su subsidiaria, SanAlto Redes Peninsular, S.A.P.I. de C.V., concesionaria del SERVICIO contratado.  
</template>

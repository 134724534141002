<script setup>
import { ref, onMounted, watch } from "vue";
import AppPlan from "@/components/AppPlan";
import { store } from "@/store";
import useApi from "@/composables/useApi";

const { service } = useApi();

const user = ref({});
const services = ref([]);
const loading = ref(false);

const getServices = async (user_id) => {
  try {
    console.log("getServices");
    const data = await service.listServicesByUser(user_id);
    if (data && data.payload) {
      services.value = data.payload;
      store.services = data.payload;
    }
  } catch (error) {
    console.error("Error getting services:", error);
  }
};

onMounted(async () => {
  try {
    // store.APP_BACK_BUTTON = true;
    store.APP_LOADING = true;
    user.value = store.user;
    
    // Check if we already have services in store
    if (store.services && store.services.length > 0) {
      services.value = store.services;
    } else if (user.value && user.value.id) {
      // If not, fetch them if we have a user
      await getServices(user.value.id);
    }
  } catch (error) {
    console.error(error);
  } finally {
    store.APP_LOADING = false;
  }
});

// Watch for changes in store.user and store.services
watch(() => store.user, async (newUser) => {
  if (newUser && newUser.id && (!services.value.length || !store.services.length)) {
    await getServices(newUser.id);
  }
}, { deep: true });

watch(() => store.services, (newServices) => {
  if (newServices && newServices.length && !services.value.length) {
    services.value = newServices;
  }
}, { deep: true });
</script>
<template lang="pug">
//- pre | {{ user }}
//- pre | {{ services }}
.home
  h1.title {{ $t("homepage.my_services") }}
  .home__body
    app-plan(v-for="service in services" :plan="service")
</template>

<style lang="scss" scoped>
.card + .card {
  @apply m-0;
}
.title {
  @apply font-medium;
  @apply text-bluegray-500;
  @apply text-base;
  @apply mb-2;
  @apply inline-block;
}
.home {
  &__body {
    @apply grid;
    @apply grid-cols-1;
    @apply gap-4;
    @screen sm {
      @apply grid-cols-2;
    }
    @screen md {
      @apply grid-cols-2;
    }
    @screen xl {
      @apply grid-cols-3;
    }
  }
}
</style>

import { axios, getToken, setToken, clearToken } from "@/utils/axios";

const fields = []

export default function usePlansApi() {
    const getPlans = async () => {
        try {
            const { data, status } = await axios.post("/api/v1/leads_teams.sales_catalog", {
                method: "get_sales_plans",
                where: [false]
            });
            return data;
        } catch (error) {
            console.error("Error listing services by user:", error);
            throw error;
        }
    }
    return { getPlans };
}

import { axios, getToken, setToken, clearToken } from "@/utils/axios";

const fields = []

export default function useContractApi() {
    const getUsoCFDI = async () => {
        try {
            const data = [
                { value: 'G01', label: 'Adquisición de mercancías.' },
                { value: 'G02', label: 'Devoluciones, descuentos o bonificaciones.' },
                { value: 'G03', label: 'Gastos en general.' },
                { value: 'I01', label: 'Construcciones.' },
                { value: 'I02', label: 'Mobilario y equipo de oficina por inversiones.' },
                { value: 'I03', label: 'Equipo de transporte.' },
                { value: 'I04', label: 'Equipo de cómputo y accesorios.' },
                { value: 'I05', label: 'Dados, troqueles, moldes, matrices y herramental.' },
                { value: 'I06', label: 'Comunicaciones telefónicas.' },
                { value: 'I07', label: 'Comunicaciones satelitales.' },
                { value: 'I08', label: 'Otra maquinaria y equipo.' },
                { value: 'D01', label: 'Honorarios médicos, dentales y gastos hospitalarios.' },
                { value: 'D02', label: 'Gastos médicos por incapacidad o discapacidad.' },
                { value: 'D03', label: 'Gastos funerales.' },
                { value: 'D04', label: 'Donativos.' },
                { value: 'D05', label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
                { value: 'D06', label: 'Aportaciones voluntarias al SAR.' },
                { value: 'D07', label: 'Primas por seguros de gastos médicos.' },
                { value: 'D08', label: 'Gastos de transportación escolar obligatoria.' },
                { value: 'D09', label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
                { value: 'D10', label: 'Pagos por servicios educativos (colegiaturas).' },
                { value: 'S01', label: 'Sin efectos fiscales.' }
            ]
            return data;
        } catch (error) {
            console.error("Error listing uso cfdi:", error);
            throw error;
        }
    }

    const getRegimenFiscal = async () => {
        try {
            const data = [
                { value: '601', label: 'General de Ley Personas Morales' },
                { value: '603', label: 'Personas Morales con Fines no Lucrativos' },
                { value: '605', label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
                { value: '606', label: 'Arrendamiento' },
                { value: '607', label: 'Régimen de Enajenación o Adquisición de Bienes' },
                { value: '608', label: 'Demás ingresos' },
                { value: '609', label: 'Consolidación' },
                { value: '610', label: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
                { value: '611', label: 'Ingresos por Dividendos (socios y accionistas)' },
                { value: '612', label: 'Personas Físicas con Actividades Empresariales y Profesionales' },
                { value: '614', label: 'Ingresos por intereses' },
                { value: '615', label: 'Régimen de los ingresos por obtención de premios' },
                { value: '616', label: 'Sin obligaciones fiscales' },
                { value: '620', label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
                { value: '621', label: 'Incorporación Fiscal' },
                { value: '622', label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
                { value: '623', label: 'Opcional para Grupos de Sociedades' },
                { value: '624', label: 'Coordinados' },
                { value: '625', label: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
                { value: '626', label: 'Régimen Simplificado de Confianza - RESICO' },
                { value: '628', label: 'Hidrocarburos' },
                { value: '629', label: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
                { value: '630', label: 'Enajenación de acciones en bolsa de valores' }
            ]
            return data;
        } catch (error) {
            console.error("Error listing regimen fiscal:", error);
            throw error;
        }
    }

    const get_ciudad_cobertura = async () => {
        try {
            const { data, status } = await axios.post("/api/v1/leads_teams.ciudad_cobertura", {
                method: "search_read",
                where: [[["active", "=", true]]],
                params: { fields: ["id", "name"], order: "name asc" },
            });
            return data;
        } catch (error) {
            console.error("Error listing ciudad cobertura:", error);
            throw error;
        }
    }

    const get_asentamiento = async () => {
        try {
            const { data, status } = await axios.post("/api/v1/leads_teams.asentamiento", {
                method: "search_read",
                where: [[["active", "=", true]]],
                params: { fields: ["id", "name", "ciudad_cobertura_id"], order: "name asc" },
            });
            
            const transformedData = data.payload.map(item => ({
                ...item,
                ciudad_cobertura_id: item.ciudad_cobertura_id[0]
            }));
            // console.log(transformedData);
            
            return transformedData;
        } catch (error) {
            console.error("Error listing asentamiento:", error);
            throw error;
        }
    }

    const get_buildings = async () => {
        try {
            const { data, status } = await axios.post("/api/v1/leads_teams.build.complex", {
                method: "search_read",
                where: [[["active", "=", true]]],
                params: { fields: ["id", "name", "asentamiento_id"], order: "name asc" },
            });
            const transformedData = data.payload.map(item => ({
                ...item,
                asentamiento_id: item.asentamiento_id[0]
            }));
            return transformedData;
        } catch (error) {
            console.error("Error listing buildings:", error);
            throw error;
        }
    }

    const create_partner = async (fields) => {
        try {
            // console.log("fields:", fields);
            const { data, status } = await axios.post("/api/v1/res.partner", {
                method: "create",
                where: [[
                    fields
                ]],
            });
            if (status === 200) {
                return data;
            } else {
                throw new Error("Error creating partner");
            }
        } catch (error) {
            console.error("Error creating partner:", error);
            throw error;
        }
    }

    const create_lead = async (fields) => {
        try {
            const { data, status } = await axios.post("/api/v1/crm.lead", {
                method: "create",
                where: [[
                    fields
                ]],
            });
            if (status === 200) {
                return data;
            } else {
                throw new Error("Error creating lead");
            }
        } catch (error) {
            console.error("Error creating lead:", error);
            throw error;
        }
    }

    const update_lead = async (lead_id, fields) => {
        try {
            const { data, status } = await axios.post("/api/v1/crm.lead", {
                method: "write",
                where: [[parseInt(lead_id)], fields],
            });
            if (status === 200) {
                return data;
            } else {
                throw new Error("Error updating lead");
            }
        } catch (error) {
            console.error("Error updating lead:", error);
            throw error;
        }
    }

    const get_lead = async (lead_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/crm.lead", {
                method: "search_read",
                where: [[["id", "=", parseInt(lead_id)]]],
                params: { fields: ["id", "name", "contract_id"], limit: 1 },
            });
            if (status === 200) {
                return data.payload[0];
            } else {
                throw new Error("Error getting lead");
            }
        } catch (error) {
            console.error("Error getting lead:", error);
            throw error;
        }
    }

    const get_account_setting = async (currency_id, team_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/leads_teams.panel.contable", {
                method: "search_read",
                where: [[["currency_id", "=", parseInt(currency_id)], ["team_id", "=", parseInt(team_id)]]],
                params: { fields: ["id", "name", "customer_account_id"], limit: 1 },
            });
            return data;
        } catch (error) {
            console.error("Error listing account setting:", error);
            throw error;
        }
    }

    const create_sale_order = async (fields) => {
        try {
            const { data, status } = await axios.post("/api/v1/sale.order", {
                method: "create",
                where: [[fields]],
            });
            if (status === 200) {
                return data;
            } else {
                throw new Error("Error creating sale order");
            }
        } catch (error) {
            console.error("Error creating sale order:", error);
            throw error;
        }
    }

    // const update_sale_order = async (sale_order_id, fields) => {
    //     try {
    //         const { data, status } = await axios.post("/api/v1/sale.order", {
    //             method: "write",
    //             where: [[parseInt(sale_order_id)], fields],
    //         });
    //         if (status === 200) {
    //             return data;
    //         } else {
    //             throw new Error("Error updating sale order");
    //         }
    //     } catch (error) {
    //         console.error("Error updating sale order:", error);
    //         throw error;
    //     }
    // }

    const confirm_sale_order = async (sale_order_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/sale.order", {
                method: "action_confirm",
                where: [[parseInt(sale_order_id)]],
            });
            if (status === 200) {
                return data;
            } else {
                throw new Error("Error confirming sale order");
            }
        } catch (error) {
            console.error("Error confirming sale order:", error);
            throw error;
        }
    }

    const create_sale_order_line = async (fields) => {
        try {
            const { data, status } = await axios.post("/api/v1/sale.order.line", {
                method: "create",
                where: [[fields]],
            });
            if (status === 200) {
                return data;
            } else {
                throw new Error("Error creating sale order line");
            }
        } catch (error) {
            console.error("Error creating sale order line:", error);
            throw error;
        }
    }

    const generate_sales_order_for_contracting = async (lead_id, currency_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/crm.lead", {
                method: "generate_sales_order_for_contracting",
                where: [[parseInt(lead_id)], parseInt(currency_id)],
            });
            if (status === 200) {
                return data;
            } else {
                throw new Error("Error creating sales order for contracting");
            }
        } catch (error) {
            console.error("Error creating sales order for contracting:", error);
            throw error;
        }
    }

    const get_load_contract = async (lead_id) => {
        try {
            console.log("Generando contrato ...");
            console.log("lead_id: ", lead_id);
            const { data, status } = await axios.post("/api/v1/crm.lead", {
                method: "generar_contrato_residencial",
                where: [[parseInt(lead_id)], false],
            });
            // console.log(data);
            if (status === 200) {
                return data;
            } else {
                throw new Error("Error getting load contract");
            }
        } catch (error) {
            console.error("Error getting load contract:", error);
            throw error;
        }
    }

    const sign_contract = async (lead_id, sign) => {
        try {
            const { data, status } = await axios.post("/api/v1/crm.lead", {
                method: "apply_contract_signature",
                where: [[parseInt(lead_id)], sign],
            });
            if (status === 200) {
                return data;
            } else {
                throw new Error("Error signing contract");
            }
        } catch (error) {
            console.error("Error signing contract:", error);
            throw error;
        }
    }

    return { getUsoCFDI, getRegimenFiscal, get_ciudad_cobertura, get_asentamiento, get_buildings, create_partner, create_lead, update_lead, get_lead, get_account_setting, create_sale_order, confirm_sale_order, create_sale_order_line, generate_sales_order_for_contracting, get_load_contract, sign_contract };
}
import { axios, getToken, setToken, clearToken } from "@/utils/axios";


// const fields = ["id", "name", "partner_id", "service_id", "amount_total", "amount_untaxed", "amount_tax", "amount_paid", "amount_residual", "state", "date", "date_due", "date_paid", "invoice_line_ids"];
const fields = ["id", "name", "servicio_id", "partner_id", "invoice_date","invoice_payment_term_id" , "invoice_date_due", "l10n_mx_edi_cfdi_state", "state", "payment_state", "amount_total", "amount_untaxed", "amount_tax", "amount_paid","amount_untaxed_signed", "amount_total_in_currency_signed", "amount_total_signed", "amount_residual","fecha_pagado"];

export default function useInvoiceApi() {
    const listInvoices = async (partner_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/account.move", {
                method: "search_read",
                where: [[["partner_id", "=", partner_id], ["state", "=", "posted"], ["move_type", "=", "out_invoice"]]],
                params: { fields: fields, order: "invoice_date_due desc" },
            });
            return data;
        } catch (error) {
            console.error("Error listing invoices by partner:", error);
            throw error;
        }
    }

    const listInvoicesByService = async (service_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/account.move", {
                method: "search_read",
            where: [[["servicio_id", "=", parseInt(service_id)], ["state", "=", "posted"], ["move_type", "=", "out_invoice"]]],
            params: { fields: fields, order: "invoice_date_due desc" },
        });
            return data;
        } catch (error) {
            console.error("Error listing invoices by service:", error);
            throw error;
        }
    }

    const countInvoicesPendingByService = async (service_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/account.move", {
                method: "search_count",
            where: [[["servicio_id", "=", parseInt(service_id)], ["state", "=", "posted"], ["payment_state", "in", ["not_paid", "partial"]], ["move_type", "=", "out_invoice"]]]
        });
            return data;
        } catch (error) {
            console.error("Error counting invoices pending by service:", error);
            throw error;
        }
    }

    const getLastInvoiceId = async (service_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/account.move", {
                method: "search_read",
            where: [[["servicio_id", "=", parseInt(service_id)], ["state", "=", "posted"], ["payment_state", "in", ["not_paid", "partial"]], ["move_type", "=", "out_invoice"]]],
            params: { limit: 1, fields: ["id", "name", "invoice_date_due"], order: "invoice_date_due desc" },
        });
            return data;
        } catch (error) {
            console.error("Error getting last invoice id:", error);
            throw error;
        }
    }

    const getInvoiceById = async (id) => {
        try {
            const { data, status } = await axios.post("/api/v1/account.move", {
                method: "search_read",
                where: [[["id", "=", id], ["state", "=", "posted"], ["move_type", "=", "out_invoice"]]],
                params: { limit: 1, fields: fields },
            });
            return data;
        } catch (error) {
            console.error("Error getting invoice:", error);
            throw error;
        }
    }

    const getPaymentHistoryInvoicesByService = async (service_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/mikrotik.paymenthistory", {
                method: "search_read",
                where: [[["isp_id", "=", parseInt(service_id)], ["status_payment", "in", ["APLICADO", "PENDIENTE"]]]],
                params: { fields: ["invoice_ids"] },
            });
            const payload = data.payload
              .map(item => item.invoice_ids)
              .flat()
              .filter(id => id !== undefined);
            return payload;
        } catch (error) {
            console.error("Error getting payment history invoices by service:", error);
            throw error;
        }
    }

    return { listInvoices, listInvoicesByService, countInvoicesPendingByService, getLastInvoiceId, getInvoiceById, getPaymentHistoryInvoicesByService };
}


export default function (e) {
    e.preventDefault();
    const formElement = e.target;
    const formData = new FormData(formElement);
    const formObject = Object.fromEntries(formData.entries());
    const fields = formObject;

    // Get all checkboxes from the form
    const checkboxes = formElement.querySelectorAll('input[type="checkbox"]');
    
    // Add checkbox values to fields object
    checkboxes.forEach((checkbox) => {
        fields[checkbox.name] = checkbox.checked;
    });

    return fields;
}
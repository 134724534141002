import { axios, getToken, setToken, clearToken } from "@/utils/axios";

const fields = ["id", "name", "x_studio_servicio", "create_date", "close_date", "stage_id"]

export default function useTicketApi() {
    const getTickets = async (user_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/helpdesk.ticket", {
                method: "search_read",
                where: [[["partner_id", "=", user_id]]],
                params: {
                    fields: fields,
                    order: "create_date desc",
                },
            });
            return data;
        } catch (error) {
            console.error("Error listing services by user:", error);
            throw error;
        }
    }
    return { getTickets };
}

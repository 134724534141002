import { reactive } from "vue";

export const store = reactive({
  APP_LOADING: false,
  APP_TITLE: "",
  APP_BACK_BUTTON: false,
  I118N_TITLE: false,
  user: {},
  services: [],
  invoices: [],
  invoicePaid: {},
  invoices_to_be_paid: [],
  sales_catalog_id: {},
  partner_id: {},
  lead_id: null,
  sale_order_id: null,
  sale_order_line_id: null,
});

import useConnectionApi from "./api/useConnection";
import useUserApi from "./api/useUser";
import useServiceApi from "./api/useService";
import useInvoiceApi from "./api/useInvoice";
import useTicketApi from "./api/useTicket";
import usePlansApi from "./api/usePlans";
import useContractApi from "./api/useContract";

export default function useApi() {
  return {
    user: { ...useUserApi() },
    connection: { ...useConnectionApi() },
    service: { ...useServiceApi() },
    invoice: { ...useInvoiceApi() },
    ticket: { ...useTicketApi() },
    plans: { ...usePlansApi() },
    contract: { ...useContractApi() },
  };
}
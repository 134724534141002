{
  "common": {
    "send": "Entrar",
    "card": "Tarjeta",
    "service": "Servicio",
    "plan": "plan",
    "next": "Siguiente"
  },
  "setting": {
    "text": "Actualización de la aplicación GigNet disponible - Haga clic para actualizar"
  },
  "plan": {
    "estatus": "Estado",
    "next_invoice": "Próxima factura",
    "type": "Tipo",
    "cut_off": "Fecha de corte",
    "subscription": "Domiciliación",
    "bank_reference": "Referencia Bancaria",
    "address": "Dirección"
  },
  "titles": {
    "Home": "Inicio",
    "Profile": "Perfil",
    "CardsIndex": "Mis tarjetas",
    "CardsNew": "Nueva tarjeta",
    "SupportIndex": "Tickets de soporte",
    "SupportAdd": "Nuevo ticket",
    "ChangePass": "Cambiar contraseña",
    "PlanDetails": "",
    "InvoicesView": "",
    "InvoicesPay": "Pagar factura",
    "QuickPaymentSuccess": "",
    "Login": "",
    "QuickPayment": "",
    "LeadForm": "",
    "ResetPass": "",
    "Map": "",
    "AddressForm": "",
    "ContractForm": "",
    "NewUser": "",
    "QuickPaymentInvoices": "Pago rápido"
  },
  "ticket": {
    "close_date": "Fecha de cierre",
    "create_date": "Fecha de creación"
  },
  "menu": {
    "home": "Planes",
    "cards": "Tarjetas",
    "support": "Soporte"
  },
  "userOptions": {
    "profile": "Perfil",
    "changePass": "Cambiar contraseña",
    "forgotPass": "Olvidé mi contraseña",
    "logout": "Salir",
    "newUser": "¿Eres cliente GigNet y aún no tienes tu usuario? Haz click aquí"
  },
  "password_reset": {
    "button": "Solicitar nueva contraseña",
    "check_mail": "Revisa tu bandeja de entrada o spam para el siguiente paso",
    "valid_email": "Correo valido",
    "success": "¡Éxito!",
    "password_changed": "Contraseña cambiada"
  },
  "map": {
    "select_address": "Las zonas de cobertura se muestran en azul.",
    "selected_address": "Ubicación seleccionada"
  },
  "lead": {
    "form_name": "Contacto",
    "name": "Empresa",
    "contact_name": "Nombre",
    "email": "Correo",
    "mobile": "Teléfono",
    "street": "Dirección",
    "type_service": "Tipo de servicio",
    "location_of_service": "Úbicación del servicio",
    "success_title": "Éxito",
    "success_subtitle": "Atención a cliente se pondrá en contacto"
  },
  "login": {
    "title": "¿Ya eres cliente GigNet?",
    "username": "Usuario",
    "password": "Contraseña",
    "send": "Entrar",
    "quick": "Pago rápido",
    "client": "Quiero ser cliente",
    "call": "Soporte",
    "notice": "Al usar esta plataforma, estás de acuerdo con nuestras <a target='_BLANK' href='https://gignet.mx/es/politica-privacidad'>políticas de privacidad</a>",
    "error_title": "Datos incorrectos",
    "error_subtitle": "Hubo un error al iniciar sesión."
  },
  "register": {
    "error_title": "Error",
    "error_subtitle": "Número de servicio incorrecto"
  },
  "changePass": {
    "current": "Actual",
    "new": "Nueva",
    "confirm": "Confirmar nueva",
    "success_title": "Listo",
    "success_subtitle": "Su contraseña ha sido cambiada exitosamente",
    "error_title": "Error",
    "error_subtitle": "Hubo un error al cambiar la contraseña"
  },
  "homepage": {
    "my_services": "Mis servicios",
    "hello": "¡Hola {name}!"
  },
  "credit_card": {
    "expires": "Válida hasta",
    "number": "Número",
    "add_error": "No se pudo agregar la tarjeta",
    "exp_month": "Mes",
    "exp_year": "Año",
    "cvc": "CVC",
    "new": "Agregar tarjeta",
    "select": "Seleccionar",
    "write": "Escribir",
    "checkbox": "Autorizo a realizar actividades de facturación y procesar pagos a través de las formas y mecanismos habilitados por GIGNET, relacionadas con el uso de bienes y/o servicios proporcionados por terceros."
  },
  "support": {
    "type": "Tipo de solicitud",
    "types": {
      "service_failures": "Fallas con el servicio",
      "payments": "Pagos y facturación",
      "plans": "Planes"
    },
    "description": "Descripción",
    "send": "Enviar"
  },
  "services": {
    "service": "Servicio #"
  },
  "payment": {
    "success_title": "¡Pago exitoso!",
    "success_description": "La factura se ha enviado al correo  asignado",
    "success_button": "Ok",
    "subscription": {
      "title": "¡Tienes una suscripción!",
      "subtitle": "El cobro se realizará en automático"
    },
    "subscription_disabled": {
      "title": "Suscripción deshabilitada",
      "subtitle": "No cumples con los requisitos para una suscripción"
    },
    "info": "Muchas gracias por su pago, este se verá reflejado en un lapso no mayor a una hora."
  },
  "profile": {
    "username": "Usuario",
    "name": "Nombre",
    "email": "Correo",
    "street": "Calle",
    "save": "Guardar",
    "business_name": "Razón social",
    "phone": "Teléfono",
    "city": "Ciudad",
    "street_number": "Calle Ext",
    "street_number2": "Calle Int",
    "colony": "Colonia",
    "locality": "Localidad",
    "reference": "Referencia"
  },
  "quickpayment": {
    "continue": "Buscar",
    "notfound_title": "No se encontraron resultados",
    "notfound_subtitle": "No se han encontrado resultados para el servicio solicitado.",
    "clean": "Limpiar resultados",
    "cc_label": "Ingrese los datos de su tarjeta para continuar",
    "service_number": "Número de servicio",
    "pay": "Pagar",
    "subscribe": "Suscribirse",
    "error_title": "Error",
    "error_subtitle": "Hubo un error al procesar el pago",
    "caption": "Revisar que la información coincida con su número de contrato, para evitar un pago erróneo de su servicio. Para su mayor seguridad se le invita siempre a ingresar al portal con su usuario y contraseña para realizar su(s) pago(s). ",
    "info": "Le recordamos que los pagos pueden verse reflejados en un lapso no mayor a una hora."
  },
  "invoices": {
    "invoice": "Folio",
    "id": "ID",
    "name": "Nombre",
    "partner_id": "Cliente",
    "plan": "Plan",
    "reference": "Referencia bancaria",
    "next_invoice_date": "Fecha de próxima factura",
    "subscription": "Domiciliación",
    "summary_amount": "Importe total pendiente",
    "summary_payment": "Monto a pagar",
    "summary_pending": "Importe pendiente",
    "servicio_id": "Servicio",
    "invoice_date": "Fecha",
    "invoice_payment_term_id": "Plazo de pago",
    "amount_total": "Total",
    "amount_residual": "Pendiente",
    "state": "Estatus",
    "paid": "Pagada",
    "pay": "Pagar",
    "pending_helper": "Tiene facturas anteriores pendientes de pago.",
    "subscription_helper": "El servicio cuenta con una suscripción, el cargo es automático a su tarjeta",
    "confirm": "Por medio de la presente autorizo a GigNet, S.A. de C.V, para que cargue a mi tarjeta de crédito o débito, la cantidad por concepto de servicios que mensualmente me presta.",
    "cancelled": "Cancelada",
    "not_payed": "Pendiente",
    "filter_by": "Filtrar por",
    "pending_payment": "Procesando",
    "message_empty": "No hay facturas pendientes de pago"
  },
  "contract": {
    "contact_form": {
      "form_name": "Datos de contacto",
      "id_vendedor": "ID Vendedor (para ser llenado por ventas si aplica)",
      "name": "Nombre",
      "email": "Email",
      "phone": "Teléfono",
      "mobile": "Celular",
      "identificacion": "Identificación",
      "comprobante": "Comprobante de domicilio",
      "business_name": "Razón Social",
      "identification": "RFC",
      "invoice_check": "Requiero factura fiscal",
      "check": "He verificado que mi información es correcta y acepto la <a target='_blank' href='https://gignet.mx/es/politica-privacidad/'>Política de Privacidad<a>"
    },
    "address_form": {
      "form_name": "Dirección de la instalación",
      "coverage_zone": "Zona de cobertura",
      "type_locality": "Tipo de asentamiento",
      "street": "Calle",
      "ext": "#Ext",
      "int": "#Int",
      "city": "Ciudad",
      "suburb": "Colonia",
      "zip_code": "Código postal",
      "settlement": "Asentamiento",
      "aditional_info": "Información adicional",
      "referal": "¿Cómo te enteraste de nosotros?",
      "channel_other": "Favor de especificar"
    }
  },
  "wizard_contract": {
    "steps": {
      "address": "Dirección de Servicio",
      "contact": "Datos de contacto",
      "plans": "Planes",
      "customer": "Datos del cliente",
      "contract": "Contrato",
      "end": "Fin"
    },
    "address": {
      "title": "Verifica disponibilidad de los nuevos servicios residenciales.",
      "city": "Ciudad",
      "zone": "Zona de cobertura",
      "township": "Dirección",
      "building": "Edificio o Complejo",
      "btn_send": "Siguiente",
      "btn_back": "Regresar",
      "box_header_text": "¿No ves tu comunidad?",
      "box_body_text": "Siempre estamos ampliando nuestra red. Si no ves tu comunidad en la lista, contáctanos utilizando el siguiente formulario si te encuentras interesado en los servicios de GigNet Home.",
      "btn_fillform": "Llenar formulario"
    },
    "plans": {
      "title": "Selecciona tu paquete",
      "prefix": "Hasta",
      "period": "Mensual",
      "install": "Instalación",
      "label_promotion": "Hasta"
    },
    "contact": {
      "title": "Información de Contacto",
      "name": "Nombre",
      "email": "Correo",
      "phone": "Teléfono",
      "city": "Ciudad",
      "colony": "Dirección",
      "building": "Edificio o Complejo",
      "zip": "Código Postal",
      "link_maps": "GPS Ubicación",
      "comment": "Información Adicional",
      "info_gps": "si te encuentras en la ubicación del servicio haz clic en el icono para generar tu ubicación gps",
      "gps_steps": {
        "one": "En tu teléfono,Tablet o computadora, abre la aplicación Google Maps",
        "two": "Busca el sitio o localízalo en el mapa y mantenlo pulsado para poner un pin. ",
        "three": "En la parte superior de la pantalla, selecciona el link del sitio.",
        "four": "Dale clic secundario y selecciona Copiar.",
        "five": "Dale pegar con el botón secundario en la sección donde te lo pide."
      },
      "btn_send": "Enviar"
    },
    "end": {
      "title": "Tu solicitud se ha generado con éxito, gracias por ayudarnos a expandirnos constantemente. Uno de nuestros ejecutivos de servicio al cliente se contactará contigo en el momento que contemos con cobertura en tu zona.",
      "subtitle": "Para más información o seguimiento no dudes en contactarnos en nuestra línea de atención. 9986900690",
      "phone": "9986900690",
      "label": "Finalizar"
    }
  },
  "signature": {
    "check": "Estoy de acuerdo con los términos y condiciones de contratación del servicio de GigNet S.A. de C.V.",
    "save": "Guardar",
    "clear": "Borrar"
  }
}
import { axios, getToken, setToken, clearToken } from "@/utils/axios";
import useInvoiceApi from "./useInvoice";

const fields = ["id", "name", "no_contrato", "partner_id", "plan_id", "ejecutivo_cs", "asentamiento_id", "plan_servicio", "estatus", "fecha_proxima_factura", "tipo_servicio", "fecha_corte", "subscription_status", "referencia_bancaria", "domicilio_servicio", "facturas_ids", "subscription_status"];

export default function useServiceApi() {
    const listServicesByUser = async (user_id) => {
        try {
            const { data, status } = await axios.post("/api/v1/mikrotik.servicio", {
                method: "search_read",
                where: [[["partner_id", "=", user_id]]],
                params: {
                    fields: fields,
                },
            });
            return data;
        } catch (error) {
            console.error("Error listing services by user:", error);
            throw error;
        }
    }

    const getServiceById = async (id) => {
        try {
            const { data, status } = await axios.post("/api/v1/mikrotik.servicio", {
                method: "search_read",
                where: [[["id", "=", id]]],
                params: {
                    limit: 1,
                    fields: fields,
                },
            });
            return data;
        } catch (error) {
            console.error("Error getting service by id:", error);
            throw error;
        }
    }

    const getServiceByNumber = async (number) => {
        try {
            const { data, status } = await axios.post("/api/v1/mikrotik.servicio", {
                method: "search_read",
                where: [[["name", "=", number]]],
                params: {
                    limit: 1,
                    fields: fields,
                },
            });
            return data;
        } catch (error) {
            console.error("Error getting service by number:", error);
            throw error;
        }
    }

    return {
        listServicesByUser,
        getServiceById,
        getServiceByNumber
    }
}

import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { trackRouter } from "vue-gtag-next";
import { differenceInMinutes } from "date-fns";
import { createRouter, createWebHistory } from "vue-router";

import { setToken, clearToken } from "@/utils/axios";
import { store } from "@/store";

import Home from "@/views/Home";
import Login from "@/views/Login";
import ChildView from "@/components/ChildView";

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      layout: "Blank",
      guest: true,
      unnavigate: true,
    },
  },
  {
    path: "/plan/:id",
    name: "PlanDetails",
    component: () =>
      import(/* webpackChunkName: "PlanDetails" */ "@/views/Plan/Details.vue"),
  },
  {
    path: "/password-change",
    name: "ChangePass",
    component: () =>
      import(
        /* webpackChunkName: "ChangePass" */ "@/views/ChangePass/index.vue"
      ),
  },
  {
    path: "/new-user",
    name: "NewUser",
    component: () =>
      import(/* webpackChunkName: "NewUser" */ "@/views/NewUser/Index.vue"),
    meta: {
      layout: "Blank",
      guest: true,
    },
  },
  {
    path: "/password-reset",
    name: "ResetPass",
    component: () =>
      import(
        /* webpackChunkName: "ResetPass" */ "@/views/Guest/PasswordReset.vue"
      ),
    meta: {
      layout: "Blank",
      guest: true,
    },
  },
  // {
  //   path: "/cards",
  //   component: ChildView,
  //   children: [
  //     {
  //       path: "",
  //       name: "CardsIndex",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "cardsIndex" */ "@/views/Cards/Index.vue"
  //         ),
  //     },
  //     {
  //       path: "new",
  //       name: "CardsNew",
  //       component: () =>
  //         import(/* webpackChunkName: "cardsNew" */ "@/views/Cards/New.vue"),
  //     },
  //   ],
  // },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
  },
  {
    path: "/info/:id?",
    name: "LeadForm",
    meta: {
      layout: "Blank",
      guest: true,
    },
    component: () =>
      import(
        // /* webpackChunkName: "LeadForm" */ "@/views/ContractWizardWorkflow/index.vue"
        /* webpackChunkName: "LeadForm" */ "@/views/ContractWizardFlow/index.vue"
      ),
  },
  {
    path: "/quick-payment/:id?",
    component: () =>
      import(
        /* webpackChunkName: "quick-payment-main" */ "@/views/Guest/QuickPayment/MainMultiple.vue"
      ),
    name: "QuickPayment",
    meta: {
      layout: "Blank",
      guest: true,
    },
  },
  {
    path: "/payment-success",
    name: "QuickPaymentSuccess",
    meta: {
      layout: "Blank",
      guest: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "quick-payment-sucess" */ "@/views/Guest/QuickPayment/PaymentSuccess.vue"
      ),
  },
  {
    path: "/payment-fail",
    name: "QuickPaymentFail",
    meta: {
      layout: "Blank",
      guest: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "quick-payment-fail" */ "@/views/Guest/QuickPayment/PaymentFail.vue"
      ),
  },
  {
    path: "/support",
    component: ChildView,
    children: [
      {
        name: "SupportIndex",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "SupportIndex" */ "@/views/Support/Index.vue"
          ),
      },
      {
        name: "SupportAdd",
        path: "new",
        component: () =>
          import(
            /* webpackChunkName: "SupportAdd" */ "@/views/Support/Add.vue"
          ),
      },
    ],
  },
  {
    path: "/invoices",
    component: ChildView,
    children: [
      {
        path: ":id",
        name: "InvoicesView",
        component: () =>
          import(
            /* webpackChunkName: "InvoicesView" */ "@/views/Invoices/View.vue"
          ),
      },
      {
        path: ":id/pay",
        name: "InvoicesPay",
        component: () =>
          import(
            /* webpackChunkName: "InvoicesPay" */ "@/views/Invoices/Pay.vue"
          ),
      },
      {
        path: ":id/quick-payment/:service_id",
        name: "QuickPaymentInvoices",
        component: () =>
          import(
            /* webpackChunkName: "QuickPaymentInvoices" */ "@/views/Invoices/QuickPayment.vue"
          ),
      },
    ],
  },
  {
    path: "/address",
    name: "AddressForm",
    meta: {
      layout: "Blank",
      guest: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "address-form" */ "@/views/Guest/Contract/AddressForm.vue"
      ),
  },
  {
    path: "/contract",
    name: "ContractForm",
    meta: {
      layout: "Blank",
      guest: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "contract-form" */ "@/views/Guest/Contract/ContractForm.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const jwt = Cookies.get("g_at");
  const refreshToken = Cookies.get("g_rt");
  let autenticado = false;

  store.APP_TITLE = "";
  store.APP_BACK_BUTTON = false;
  store.I118N_TITLE = true;

  if (jwt) {
    const decoded = jwt_decode(jwt);
    const sub = decoded.sub;
    let stringJSONValido = sub.replace(/'/g, '"');
    const user = JSON.parse(stringJSONValido);
    const { metadata } = user;
    const d = differenceInMinutes(decoded.exp * 1000, Date.now());
    if (d <= 5) {
      if (d <= 0) {
        clearToken();
        autenticado = false;
      }
      // const { data } = await axios.post("/auth/refresh");
      // setToken(data.token);
    } else {
      // Check if user is authenticated
      if (metadata.type === "user") {
        setToken({ access_token: jwt, refresh_token: refreshToken });
        store.user = metadata;
        autenticado = true;
      } else {
        // Clear token with public user
        clearToken();
        autenticado = false;
      }
    }
  }

  if (!to.meta.guest) {
    if (autenticado) {
      next();
    } else {
      next({
        name: "Login",
        query: { redirectFrom: to.fullPath },
      });
    }
  } else {
    if (to.name === "Login" && autenticado) {
      next("/home");
    } else {
      next();
    }
  }
});

router.afterEach((to) => {
  store.APP_TITLE = `titles.${to.name}`;
  store.APP_BACK_BUTTON = false;
  store.APP_LOADING = true;
});

trackRouter(router);

export default router;
